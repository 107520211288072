import { Component, EventEmitter, Output } from '@angular/core'
import { TypeDocument, typesDocument } from '../../../../../data-access/constant/types-document'
import { JsonPipe, NgForOf, NgIf } from '@angular/common'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'

@Component({
    selector: 'app-document-upload',
    standalone: true,
    imports: [NgForOf, ReactiveFormsModule, JsonPipe, NgIf],
    templateUrl: './document-upload.component.html',
    styleUrl: './document-upload.component.scss',
})
export class DocumentUploadComponent {
    @Output()
    uploadDocument = new EventEmitter<DocumentUploadFormValue>()
    protected form: FormGroup = new FormGroup<DocumentUploadForm>({
        fileName: new FormControl('', Validators.required),
        codeTypeDocumentGed: new FormControl('', Validators.required),
        file: new FormControl(null, Validators.required),
    })
    protected typesDocument: TypeDocument[] = typesDocument

    onSubmit(): void {
        if (this.form.valid) {
            this.uploadDocument.emit(this.form.value)
        }
    }

    onDocumentPicked(file: File) {
        this.form.patchValue({ file })
    }
}

export interface DocumentUploadForm {
    fileName: FormControl<string>
    codeTypeDocumentGed: FormControl<string>
    file: FormControl<File>
}

export interface DocumentUploadFormValue {
    fileName: string
    codeTypeDocumentGed: string
    file: File
}
