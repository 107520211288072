<div class="d-flex flex-column align-items-center text-center page-content">
    <h1>Aucun chantier affecté</h1>
    <p>Vous n'avez aucun chantier affecté à votre utilisateur. Contactez votre administrateur.</p>
    <p>Vous pouvez cependant accéder à :</p>
    <div class="d-flex flex-row gap-3">
        <button (click)="redirectToPointageChantier()" class="btn red-filter-logo btn-outline-primary link-button p-2 custom-size">
            <span class="d-flex align-items-center justify-content-center">
                <img class="pe-2" src="assets/images/logos/POINTAGE_COMPAGNONS.svg" width="40" height="40"
                     alt="Pointage Chantier" />
                <span class="ms-2">Pointage Chantier</span>
            </span>
        </button>
        <button (click)="redirectToPointageChantier360()" class="btn red-filter-logo btn-outline-primary link-button p-2 custom-size">
            <span class="d-flex align-items-center justify-content-center ">
              <img class="pe-2" src="assets/images/logos/POINTAGE_CHANTIER_360.svg" width="40" height="40"
                   alt="Pointage Chantier 360" />
              <span class="ms-2">Pointage Chantier 360</span>
            </span>
        </button>
    </div>
</div>
