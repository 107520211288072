import { inject, Injectable } from '@angular/core'
import { EvenementDto } from '../data-access/http/dto/evenement-dto'
import { UserState } from '../data-access/state/user.state'
import { EvenementHttpService } from '../data-access/http/evenement-http.service'
import { Observable } from 'rxjs'

import { Application } from '../models/application'
import { Salarie } from '../models/salarie'
import { Interimaire } from '../models/interimaire'
import { ChantierState } from '../data-access/state/chantier.state'

@Injectable({ providedIn: 'root' })
export class EvenementDomainService {
    private userState = inject(UserState)
    private chantierState = inject(ChantierState)
    private evenementService = inject(EvenementHttpService)

    // Actions
    createSelectionChantierEvenement(codeChantier: string): Observable<EvenementDto> {
        const now = new Date()
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const evenement: EvenementDto = {
            codeChantier,
            codeTypeEvenement: 'SELECTION_CHANTIER',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } accède au chantier ${codeChantier} le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
        }
        return this.evenementService.createEvenement(evenement)
    }

    createAccesDroitsRhEvenement(): Observable<EvenementDto> {
        const now = new Date()
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const evenement: EvenementDto = {
            codeTypeEvenement: 'ACCES_RECHERCHE_RH',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a accédé à la page de recherche RH le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
        }
        return this.evenementService.createEvenement(evenement)
    }

    createSelectionPortletEvenement(codeChantier: string, application: Application): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeApplication: application.code,
            codeChantier,
            codeTypeEvenement: 'SELECTION_PORTLET',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le portlet ${
                application.name
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createSelectionPortletInactifEvenement(codeChantier: string, application: Application): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeApplication: application.code,
            codeChantier,
            codeTypeEvenement: 'SELECTION_PORTLET_INACTIF',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le portlet ${
                application.name
            } depuis la section Solutions non activées sur le chantier ${codeChantier} le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createAccesAccueilSecuriteEvenementForSalarie(salarie: Salarie): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_BTN_ACCUEIL_SECU',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a accédé à la page d'accueil à la sécurité sur chantier depuis la page Accueil sur Chantier pour le salarié ${
                salarie.codeRh
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            codeRhSalarie: salarie.codeRh,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createAccesAccueilSecuriteEvenementForInterim(interim: Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_BTN_ACCUEIL_SECU',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a accédé à la page d'accueil à la sécurité sur chantier depuis la page Accueil sur Chantier pour l’intérimaire ${
                interim.matricule
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            matriculeInterimaire: interim.matricule,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createGenerationPdfSecuriteEvenementForSalarie(salarie: Salarie): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_GEN_REGISTRE_SECU',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a généré un registre d'accueil sécurité pour le salarié ${
                salarie.codeRh
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            codeRhSalarie: salarie.codeRh,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createGenerationPdfSecuriteEvenementForInterim(interimaire: Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_GEN_REGISTRE_SECU',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a généré un registre d'accueil sécurité pour l'intérimaire ${
                interimaire.matricule
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            matriculeInterimaire: interimaire.matricule,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createAccueilPcoEvenementForInterim(interimaire: Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_BTN_ACCUEIL_PCO',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur le bouton "Accueillir dans Pointage Compagnons" depuis la page Accueil sur Chantier pour l’intérimaire ${
                interimaire.matricule
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            matriculeInterimaire: interimaire.matricule,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createAccueilPcoEvenementForSalarie(salarie: Salarie): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_BTN_ACCUEIL_PCO',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur le bouton "Accueillir dans Pointage Compagnons" depuis la page Accueil sur Chantier pour le salarié ${
                salarie.codeRh
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            codeRhSalarie: salarie.codeRh,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createAccueilChantierPortletEvenement(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'ACCES_ACCUEIL_CHANTIER_PORTLET',
            date: now,
            utilisateurId: connectedUser.id,
            codeChantier: selectedChantierCode,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur le bouton Accéder à la page d’accueil depuis le Portlet Accueil Chantier dans la section Outils le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createClickVideoSecuEvenement(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_BTN_VIDEO_ACCUEIL_SECU',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur le bouton Vidéo d'accueil sécurité depuis la section Règles de sécurité et bonnes pratiques de la page Registre d’accueil à la sécurité le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createAccesAccueilChantierDepuisDonnesRhForSalarie(salarie: Salarie): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'ACCES_ACCUEIL_CHANTIER_PAGE_RH',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur le bouton "Accueillir sur chantier" depuis la page des données RH après avoir fait une recherche sur le salarié ${
                salarie.codeRh
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            codeRhSalarie: salarie.codeRh,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createAccesAccueilChantierDepuisDonnesRhForInterim(interim: Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'ACCES_ACCUEIL_CHANTIER_PAGE_RH',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur le bouton "Accueillir sur chantier" depuis la page des données RH après avoir fait une recherche sur l'intérimaire ${
                interim.matricule
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            matriculeInterimaire: interim.matricule,
        }

        return this.evenementService.createEvenement(evenement)
    }

    createClickOpenDocumentForInterimEvent(interim: Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_OPEN_COLLAB_DOCUMENT',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur un document pour le télécharger depuis la page des données RH après avoir fait une recherche sur l'intérimaire ${
                interim.matricule
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            matriculeInterimaire: interim.matricule,
        }
        return this.evenementService.createEvenement(evenement)
    }

    createClickOpenDocumentForSalarieEvent(salarie: Salarie): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_OPEN_COLLAB_DOCUMENT',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur un document pour le télécharger depuis la page des données RH après avoir fait une recherche sur le salarié ${
                salarie.codeRh
            } le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
            codeRhSalarie: salarie.codeRh,
        }
        return this.evenementService.createEvenement(evenement)
    }

    createClickAccesDocumentationEvent(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const now = new Date()
        const evenement: EvenementDto = {
            codeTypeEvenement: 'CLIQUE_PORTLET_DOCUMENTATION',
            date: now,
            utilisateurId: connectedUser.id,
            commentaire: `L'utilisateur ${
                connectedUser.idUtilisateurSi
            } a cliqué sur "Accéder à la documentation" dans la portlet Documentation Chantier, le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`,
        }
        return this.evenementService.createEvenement(evenement)
    }
}
