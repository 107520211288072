import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core'
import { GridApi, IServerSideDatasource } from 'ag-grid-community'
import { AgGridAngular } from 'ag-grid-angular'
import { DocumentGedDto } from '../../../../../shared/data-access/http/dto/document-ged.dto'
import 'ag-grid-enterprise'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
    DocumentListComponent,
    UpdateDocumentIsActiveEvent,
    UpdateDocumentIsFavoriEvent,
} from './document-list/document-list.component'
import { DocumentUploadComponent, DocumentUploadFormValue } from './document-upload/document-upload.component'

@Component({
    selector: 'app-admin-documentation-modal',
    standalone: true,
    imports: [AgGridAngular, DocumentListComponent, DocumentUploadComponent],
    templateUrl: './admin-documentation-modal.component.html',
    styleUrl: './admin-documentation-modal.component.scss',
})
export class AdminDocumentationModalComponent {
    @Input()
    documentsDataSource: IServerSideDatasource
    @Input()
    chantierCode: string
    grid: GridApi
    @Output()
    readonly documentClicked = new EventEmitter<DocumentGedDto>()
    @Output()
    readonly updateDocumentIsActive = new EventEmitter<UpdateDocumentIsActiveEvent>()
    @Output()
    readonly updateDocumentIsFavori = new EventEmitter<UpdateDocumentIsFavoriEvent>()
    @Output()
    readonly uploadDocument = new EventEmitter<DocumentUploadFormValue>()
    destroyRef = inject(DestroyRef)

    private readonly modalService = inject(NgbModal)

    dismissModal(): void {
        this.modalService.dismissAll()
    }

    onGridReady(api: GridApi) {
        this.grid = api
    }

    onUpdateDocumentIsActive(event: UpdateDocumentIsActiveEvent): void {
        this.updateDocumentIsActive.emit(event)
    }

    onUpdateDocumentIsFavori(event: UpdateDocumentIsFavoriEvent): void {
        this.updateDocumentIsFavori.emit(event)
    }

    onUploadDocument(event: DocumentUploadFormValue) {
        this.uploadDocument.emit(event)
    }

    onDocumentClicked(event: DocumentGedDto) {
        this.documentClicked.emit(event)
    }
}
