<div class="container py-0 px-0 h-100">
    <div *ngIf="data?.length > 0">
        <div class="row pb-3">
            <div class="col-8 text-truncate" title="Date de dernière arrivée">Date de prochaine sortie</div>
            <div class="col-4">
                <div class="row ms-1">
                    <div class="fw-semibold text-nowrap ps-0 fs-6 text-end">
                        {{ dateDerniereArrivee ? dateDerniereArrivee.toLocaleDateString() : "-" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row ps-2 pb-0 table-responsive">
            <table class="table table-borderless table-hover">
                <caption class="visually-hidden">
                    Données intérimaires
                </caption>
                <thead class="border-bottom border-primary">
                    <tr>
                        <th class="portlet-table-cell lg-red" scope="col" title="Date">Date</th>
                        <th class="portlet-table-cell lg-red" scope="col" title="Nom">Nom</th>
                        <th class="portlet-table-cell lg-red" scope="col" title="Prénom">Prénom</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let interimaire of data | slice: 0 : 5">
                        <td
                            class="portlet-table-cell text-truncate"
                            title="{{ interimaire.dateFinMissionActuelle | displayDate }}"
                        >
                            {{ interimaire.dateFinMissionActuelle | displayDate }}
                        </td>
                        <th
                            class="fw-normal portlet-table-cell text-truncate"
                            scope="row"
                            title="{{ interimaire.nom ? interimaire.nom : '-' }}"
                        >
                            {{ interimaire.nom ? interimaire.nom : "-" }}
                        </th>
                        <th
                            class="fw-normal portlet-table-cell text-truncate"
                            scope="row"
                            title="{{ interimaire.prenom ? interimaire.prenom : '' }}"
                        >
                            {{ interimaire.prenom ? interimaire.prenom : "" }}
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="!(data?.length > 0)">
        <p class="text fs-6 fw-semibold">Aucun intérimaire actuellement sur le chantier</p>
    </div>
    <div class="row app-link pt-2">
        <p (click)="openDonneesInterimairesModale()" class="text-end pe-0" style="cursor: pointer">
            Afficher l'historique des données
            <i class="bi bi-box-arrow-up-right"></i>
        </p>
    </div>
</div>

<ng-template #donneesInterimairesModale>
    <div class="modal-header">
        <h4 class="modal-title">Données Intérimaires Chantier {{ chantierCode }}</h4>
        <button
            (click)="dismissDonneesInterimairesModale()"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body d-flex flex-column justify-content-between">
        <div class="row pt-3">
            <p class="text text-center text">
                Pour plus de détails, veuillez consulter
                <a
                    href="http://srv-qsenseadmin.leon-grosse.fr/sense/app/728c545d-283d-48dd-b2bd-0d8bb267ba5b"
                    target="_blank"
                    >Gestion de l'Intérim 360</a
                >
            </p>
        </div>
        <div class="row h-100" id="interimaireTableContainer">
            <div *ngIf="!interimairesPageIsLoading && totalInterimairesNumber > 0" class="table-responsive">
                <table class="table table-borderless table-hover">
                    <caption class="visually-hidden">
                        Données intérimaires
                    </caption>
                    <thead class="border-bottom border-primary">
                        <tr>
                            <th scope="col" title="Nom">Nom</th>
                            <th scope="col" title="Prénom">Prénom</th>
                            <th scope="col" title="ETT">ETT</th>
                            <th scope="col" title="N° carte BTP">N° carte BTP</th>
                            <th scope="col" title="Nationalité">Nationalité</th>
                            <th scope="col" title="Date de naissance">Date de naissance</th>
                            <th scope="col" title="Poste - Qualif">Poste - Qualif</th>
                            <th scope="col" title="Date démarrage">Date démarrage</th>
                            <th scope="col" title="Date fin initiale">Date fin de mission</th>
                            <th scope="col" title="Taux horaire">Taux horaire</th>
                            <th scope="col" title="Coeff">Coeff</th>
                            <th scope="col" title="Coût transport">Coût transport</th>
                            <th scope="col" title="Statut intérimaire">Statut intérimaire</th>
                            <th scope="col" title="Signature">Signature</th>
                            <th scope="col" title="Échéance visite médicale">Échéance visite médicale</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let interimaire of interimairesCurrentPage">
                            <th
                                class="fw-normal text-truncate"
                                scope="row"
                                title="{{ interimaire.nom ? interimaire.nom : '-' }}"
                            >
                                {{ interimaire.nom ? interimaire.nom : "-" }}
                            </th>
                            <td class="text-truncate" title="{{ interimaire.prenom ? interimaire.prenom : '-' }}">
                                {{ interimaire.prenom ? interimaire.prenom : "-" }}
                            </td>
                            <td class="text-truncate" title="{{ interimaire.ett ? interimaire.ett : '-' }}">
                                {{ interimaire.ett ? interimaire.ett : "-" }}
                            </td>
                            <td class="text-truncate" title="{{ interimaire.carteBtp ? interimaire.carteBtp : '-' }}">
                                {{ interimaire.carteBtp ? interimaire.carteBtp : "-" }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{ interimaire.nationaliteNom ? interimaire.nationaliteNom : '-' }}"
                            >
                                {{ interimaire.nationaliteNom ? interimaire.nationaliteNom : "-" }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{
                                    interimaire.dateDeNaissance ? interimaire.dateDeNaissance.toLocaleDateString() : '-'
                                }}"
                            >
                                {{
                                    interimaire.dateDeNaissance ? interimaire.dateDeNaissance.toLocaleDateString() : "-"
                                }}
                            </td>
                            <td class="text-truncate" title="{{ getPosteQualif(interimaire) }}">
                                {{ getPosteQualif(interimaire) }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{
                                    interimaire.dateDemarrage ? interimaire.dateDemarrage.toLocaleDateString() : '-'
                                }}"
                            >
                                {{ interimaire.dateDemarrage ? interimaire.dateDemarrage.toLocaleDateString() : "-" }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{
                                    interimaire.dateFinInitiale ? interimaire.dateFinMission.toLocaleDateString() : '-'
                                }}"
                            >
                                {{
                                    interimaire.dateFinInitiale ? interimaire.dateFinMission.toLocaleDateString() : "-"
                                }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{ interimaire.tauxHoraire ? interimaire.tauxHoraire : '-' }}"
                            >
                                {{ interimaire.tauxHoraire ? interimaire.tauxHoraire : "-" }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{ interimaire.coefficient ? interimaire.coefficient : '-' }}"
                            >
                                {{ interimaire.coefficient ? interimaire.coefficient : "-" }}
                            </td>
                            <td class="text-truncate" title="{{ interimaire.transport ? interimaire.transport : '-' }}">
                                {{ interimaire.transport ? interimaire.transport : "-" }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{ interimaire.statutInterimaire ? interimaire.statutInterimaire : '-' }}"
                            >
                                {{ interimaire.statutInterimaire ? interimaire.statutInterimaire : "-" }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{
                                    interimaire.dateSignature ? interimaire.dateSignature.toLocaleDateString() : '-'
                                }}"
                            >
                                {{ interimaire.dateSignature ? interimaire.dateSignature.toLocaleDateString() : "-" }}
                            </td>
                            <td
                                class="text-truncate"
                                title="{{
                                    interimaire.dateEcheanceDeLaVisiteMedicale
                                        ? interimaire.dateEcheanceDeLaVisiteMedicale.toLocaleDateString()
                                        : '-'
                                }}"
                            >
                                {{
                                    interimaire.dateEcheanceDeLaVisiteMedicale
                                        ? interimaire.dateEcheanceDeLaVisiteMedicale.toLocaleDateString()
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="!interimairesPageIsLoading && totalInterimairesNumber === 0">
            <p class="text fs-6 fw-bold">Aucun intérimaire sur le chantier</p>
        </div>
        <div class="row" *ngIf="interimairesPageIsLoading">
            <span class="spinner-border spinner-border interimaires-spinner"></span>
        </div>
        <div class="row">
            <div class="row" *ngIf="!interimairesPageIsLoading && totalPageNumber > 1">
                <p class="pt-3 fw-semibold mb-0 d-flex align-content-center justify-content-center">
                    {{ 1 + pageSize * currentPageNumber }}
                    -
                    {{
                        pageSize * (currentPageNumber + 1) < totalInterimairesNumber
                            ? pageSize * (currentPageNumber + 1)
                            : totalInterimairesNumber
                    }}
                    sur
                    {{ totalInterimairesNumber }}
                </p>
            </div>
            <ngb-pagination
                class="p-3 d-flex align-content-center justify-content-center"
                *ngIf="totalPageNumber && totalPageNumber > 1"
                [collectionSize]="totalInterimairesNumber"
                [maxSize]="5"
                [rotate]="true"
                [pageSize]="pageSize"
                (pageChange)="onPageChange($event)"
                [boundaryLinks]="true"
                [disabled]="interimairesPageIsLoading"
            ></ngb-pagination>
        </div>
    </div>
    <div
        (click)="downloadCsvFile()"
        class="modal-footer download-csv-button d-flex align-content-center justify-content-center"
        style="min-height: 4.5rem"
    >
        <span *ngIf="downloadingCsvFile" class="spinner-border"></span>
        <span *ngIf="!downloadingCsvFile"
            ><i class="bi bi-filetype-csv fs-4"></i>&nbsp;&nbsp;&nbsp;Télécharger au format CSV</span
        >
    </div>
</ng-template>
