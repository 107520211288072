<div *ngIf="!data?.length" class="text-center h-100 pt-4">Aucune donnée trouvée</div>
<table *ngIf="data?.length" class="table table-hover table-borderless">
    <thead class="border-bottom border-primary">
        <tr>
            <th class="portlet-table-cell lg-red">Type</th>
            <th class="portlet-table-cell lg-red">Collaborateur</th>
            <th class="portlet-table-cell lg-red">Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let document of data" (click)="downloadDocument(document)" role="button">
            <td>
                <span class="max-height-2lh" [title]="document.typeDocumentGed_nom">{{
                    document.typeDocumentGed_nom
                }}</span>
            </td>
            <td>
                <span class="max-height-2lh" [title]="document.collaborateur">{{ document.collaborateur }}</span>
            </td>
            <td>
                <span class="max-height-2lh">{{ document.createdDate | date: "dd/MM/yyyy" }}</span>
            </td>
        </tr>
    </tbody>
</table>
<div class="row app-link pt-2">
    <p (click)="openDocumentsModale()" class="text-end pe-0" style="cursor: pointer">
        Accéder aux documents
        <i class="bi bi-box-arrow-up-right"></i>
    </p>
</div>
