export type TypeDocumentCode =
    | 'ACCUEIL_SECURITE'
    | 'AUTORISATION_CONDUITE'
    | 'SUIVI_ACIERS'
    | 'SUIVI_DECHETS'
    | 'SUIVI_PLANNING'

export const typesDocument: TypeDocument[] = [
    { code: 'ACCUEIL_SECURITE', label: 'Accueil sécurité' },
    { code: 'AUTORISATION_CONDUITE', label: 'Autorisation de conduite' },
    { code: 'SUIVI_ACIERS', label: 'Suivi des aciers' },
    { code: 'SUIVI_DECHETS', label: 'Suivi des déchets' },
    { code: 'SUIVI_PLANNING', label: 'Suivi du planning' },
]
export interface TypeDocument {
    code: TypeDocumentCode
    label: string
}
