import { PointageCompagnonPortletBodyComponent } from '../../ui/portlet-body/pointage-compagnon-portlet-body/pointage-compagnon-portlet-body.component'
import { BetonLeonPortletBodyComponent } from '../../ui/portlet-body/beton-leon-portlet-body/beton-leon-portlet-body.component'
import { LocmatPortletBodyComponent } from '../../ui/portlet-body/locmat-portlet-body/locmat-portlet-body.component'
import { DonnesInterimairesPortletBodyComponent } from '../../ui/portlet-body/donnes-interimaires-portlet-body/donnes-interimaires-portlet-body.component'
import { ApplicationCodeEnum } from '../../../shared/models/enums/application-code-enum'
import { Application } from '../../../shared/models/application'
import { PlanningLivraisonPortletBodyComponent } from '../../ui/portlet-body/planning-livraison-portlet-body/planning-livraison-portlet-body.component'
import { At360EntitesPortletBodyComponent } from '../../ui/portlet-body/at360-entites-portlet-body/at360-entites-portlet-body.component'
import { ActionsBetonLeonPortletBodyComponent } from '../../ui/portlet-body/actions-beton-leon-portlet-body/actions-beton-leon-portlet-body.component'
import { Achats360PortletBodyComponent } from '../../ui/portlet-body/achats-360-portlet-body/achats360-portlet-body.component'
import { At360ChantierPortletBodyComponent } from '../../ui/portlet-body/at360-chantier-portlet-body/at360-chantier-portlet-body.component'
import { KairnialPortletBodyComponent } from '../../ui/portlet-body/kairnial-portlet-body/kairnial-portlet-body.component'
import { Pointage360PortletBodyComponent } from '../../ui/portlet-body/pointage360-portlet-body/pointage360-portlet-body.component'
import { AuditsLogementsPortletBodyComponent } from '../../ui/portlet-body/audits-logements-portlet-body/audits-logements-portlet-body.component'
import { RendezVousLocatairesPortletBodyComponent } from '../../ui/portlet-body/rendez-vous-locataires-portlet-body/rendez-vous-locataires-portlet-body.component'
import { DocumentationChantierPortletBodyComponent } from '../../ui/portlet-body/documentation-chantier-portlet-body/documentation-chantier-portlet-body.component'

export const ApplicationSettings: Map<ApplicationCodeEnum, Partial<Application>> = new Map([
    [
        ApplicationCodeEnum.POINTAGE_COMPAGNONS,
        {
            bodyComponent: PointageCompagnonPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.BETON_LEON,
        {
            bodyComponent: BetonLeonPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.LOCMAT,
        {
            bodyComponent: LocmatPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.DONNEES_INTERIMAIRES,
        {
            bodyComponent: DonnesInterimairesPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.PLANNING_LIVRAISON_CHANTIER,
        {
            bodyComponent: PlanningLivraisonPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [ApplicationCodeEnum.KAIRNIAL, { activeByDefault: true, bodyComponent: KairnialPortletBodyComponent }],
    [
        ApplicationCodeEnum.AT360_ENTITES,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: At360EntitesPortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.AT360_CHANTIER,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: At360ChantierPortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.ACTIONS_BETON_LEON,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: ActionsBetonLeonPortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.ACHATS_360,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: Achats360PortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.POINTAGE_CHANTIER_360,
        {
            displayDescUnderTitle: true,
            activeByDefault: true,
            bodyComponent: Pointage360PortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.AUDITS_LOGEMENTS,
        {
            disableLogoFilter: true,
            bodyComponent: AuditsLogementsPortletBodyComponent,
            activeLinkText: "Accéder à l'application sur PC",
        },
    ],
    [
        ApplicationCodeEnum.RENDEZ_VOUS_LOCATAIRES,
        {
            disableLogoFilter: true,
            bodyComponent: RendezVousLocatairesPortletBodyComponent,
            activeLinkText: "Accéder à l'application sur PC",
        },
    ],
    [
        ApplicationCodeEnum.DOCUMENTATION_CHANTIER,
        {
            bodyComponent: DocumentationChantierPortletBodyComponent,
            displayDescUnderTitle: false,
        },
    ],
])
