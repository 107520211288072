<ag-grid-angular
    #grid
    class="ag-theme-quartz"
    [serverSideDatasource]="documentsDataSource"
    [rowModelType]="'serverSide'"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColumnDef"
    [gridOptions]="gridOptions"
    [pagination]="true"
    [paginationPageSizeSelector]="paginationPageSizeSelector"
    [paginationPageSize]="paginationPageSize"
    [maxBlocksInCache]="10"
    [cacheBlockSize]="5"
    [localeText]="localeFr"
    [suppressMovableColumns]="true"
    (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
