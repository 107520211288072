import { Component} from '@angular/core'
import { environment } from '../../../../environments/environment'

@Component({
    selector: 'app-lg-no-chantier',
    templateUrl: './no-chantier.component.html',
    styleUrls: ['./no-chantier.component.scss'],
    standalone: true,
})
export class NoChantierComponent {

    redirectToPointageChantier(): void {
        window.open(environment.pointageUrl, '_blank');

    }

    redirectToPointageChantier360(): void {
        window.open(environment.pointage360Url, '_blank');
    }

}
